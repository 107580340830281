const themeOverrides = {
  token: {
    colorPrimary: "#1b0035",
    colorPrimaryBg: "#1b0035",
    colorPrimaryText: "#1b0035"
  },
  components: {
    Button: {
      defaultBg: "#e2d0a1",
      defaultColor: "black",
      defaultHoverBg: "#e2d0a1ee",
      defaultHoverColor: "black",
      defaultHoverBorderColor: "#e2d0a1",
      defaultActiveBg: "#e2d0a1ee",
      defaultActiveBorderColor: "#e2d0a1",
      defaultActiveColor: "black"
    },
  },
}

export default themeOverrides;