import { combineReducers } from "@reduxjs/toolkit";
import analysis from "./analysis/analysisSlice";
import app from "./app/appSlice";
import auth from "./auth/authSlice";
import conversation from "./conversation/conversationSlice";
import plan from "./plan/planSlice";
import user from "./user/userSlice";

//Include all the reducer to combine and provide to configure store.
const rootReducer = {
    app,
    auth,
    plan,
    user,
    conversation,
    analysis,
};

export default combineReducers(rootReducer);
