import React, { useRef, useState } from 'react';
import { Button, Modal, message, Typography, Radio, Input, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LockOutlined } from '@ant-design/icons';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import AbbySrc from '../../../../assets/images/Abby_3x.png';
import { getUser } from '../../../../redux/auth/authSlice';

import { createSetupIntent, createSubscription } from '../../../../services/planAPI';

const { Title } = Typography;

function PayModal({ open, setOpen, price, planId, setSuccessful }) {
  const cardNumberRef = useRef();
  const cardExpiryRef = useRef();
  const cardCvcRef = useRef();

  // stripe items
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const errors = useSelector((state) => state.auth.errors);
  const [cardType, setCardType] = useState(1);

  const [cardError, setCardError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleAddPaymentMethod = async () => {
    try {
      setLoading(true);
      // create a payment method
      let paymentMethod;
      if (!user.pm_last_four || (user.pm_last_four && cardType === 2)) {
        paymentMethod = await stripe?.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: user.name,
            email: user.email,
          },
        });

        if (paymentMethod?.error) {
          console.log(paymentMethod.error);
          setCardError({
            ...cardError,
            [paymentMethod.error.code.replace('incomplete_', '')]: paymentMethod.error.message,
          });
          setLoading(false);
          return;
        }
      }

      const response = await createSetupIntent();
      let res = {};
      if (!user.pm_last_four && paymentMethod?.paymentMethod?.id) {
        res = await stripe.confirmCardSetup(response.data.clientSecret, {
          payment_method: paymentMethod?.paymentMethod?.id,
        });
      }
      const { setupIntent, error } = res;
      if (error) {
        setLoading(false);
        return message.error(error.message);
      }

      let { data } = await createSubscription({
        paymentMethod: paymentMethod?.paymentMethod?.id,
        pm_type: paymentMethod?.paymentMethod?.card.brand,
        pm_last_four: paymentMethod?.paymentMethod?.card.last4,
        customerId: response.data.customerId,
        planId,
      });
      if (data.clientSecret) {
        const res1 = await stripe.confirmCardPayment(data.clientSecret);
        if (res1.error) {
          setLoading(false);
          return message.error(res1.error.message);
        }
      }
      dispatch(getUser());
      setOpen(false);
      setLoading(false);
      setTimeout(() => navigate('/playground'), 1000);
      message.success('Successfully subscribed!');
      window.gtag('event', 'conversion', {'send_to': 'AW-16565329006/84qjCODxss0ZEO64-9o9'});
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else if (error.message) {
        message.error(error.message);
      }
    }
  };

  return (
    <Modal
      className="my-2 shadow-none"
      width={460}
      title={
        <div className='flex flex-col'>
          <div className='flex items-center justify-center mb-4'>
            <img src={AbbySrc} alt="abby_logo" width={70} className='mr-2' />
            <Tag className='font-bold text-primary'>Pro</Tag>
          </div>
          <div className="text-center px-4 py-1.5 rounded-lg mx-4 bg-[#e2d0a1]">
            <Title level={4} className="!mb-1 !text-purple-950 !font-[700]">
              Only ${price} / Month
            </Title>
            <Title level={5} className="!m-0 !font-normal !text-black italic">
              Unlimited Messages. Cancel Anytime.
            </Title>
          </div>
        </div>
      }
      open={open}
      centered
      footer={[]}
      maskClosable={true}
      styles={{
        mask: { background: '#1b003533', },
        content: { background: '#35075e', height: '480px', placeContent: 'center', boxShadow: 'none' },
        header: { background: 'transparent' },
      }}
      onCancel={() => setOpen(false)}
      closable={false}
    >
      <div className="grid gap-2 m-auto">
        <div className="grid gap-2 px-4">
          {user.pm_last_four && (
            <Radio.Group
              onChange={(e) => {
                setCardType(e.target.value);
                setCardError({});
              }}
              value={cardType}
            >
              <Radio value={1} style={{ color: 'white' }}>
                Use Old Card
              </Radio>
              <Radio value={2} style={{ color: 'white' }}>
                Use New Card
              </Radio>
            </Radio.Group>
          )}
          {cardType === 1 && user.pm_last_four && (
            <div className="mt-2">
              <Input size="large" readOnly value={`* * * *  * * * *  * * * *  ${user.pm_last_four}`} />
            </div>
          )}

          {(cardType === 2 || !user.pm_last_four) && (
            <>
              <CardNumberElement
                className="px-4 py-3 mt-4 bg-white border border-gray-400 border-solid rounded"
                onChange={(event) => {
                  if (event.error) {
                    setCardError({ ...cardError, number: event.error.message });
                  } else {
                    setCardError({
                      ...cardError,
                      number: null,
                    });
                  }
                  if (event.complete) {
                    cardExpiryRef.current.focus();
                  }
                }}
                onReady={(element) => (cardNumberRef.current = element)}
              />
              {cardError.number && <div className="text-sm text-red-500">{cardError.number}</div>}
              <div className="flex flex-wrap justify-between">
                <div className="w-[50%]">
                  <CardExpiryElement
                    className="px-4 py-3 mt-4 bg-white border border-gray-400 border-solid rounded"
                    onChange={(event) => {
                      if (event.error) {
                        setCardError({
                          ...cardError,
                          expiry: event.error.message,
                        });
                      } else {
                        setCardError({
                          ...cardError,
                          expiry: null,
                        });
                      }
                      if (event.complete) {
                        cardCvcRef.current.focus();
                      } else if (event.empty) {
                        cardNumberRef.current.focus();
                      }
                    }}
                    onReady={(element) => (cardExpiryRef.current = element)}
                  />
                  {cardError.expiry && <div className="text-sm text-red-500">{cardError.expiry}</div>}
                </div>
                <div className="w-[40%]">
                  <CardCvcElement
                    className="px-4 py-3 mt-4 bg-white border border-gray-400 border-solid rounded"
                    onChange={(event) => {
                      if (event.error) {
                        setCardError({
                          ...cardError,
                          cvc: event.error.message,
                        });
                      } else {
                        setCardError({
                          ...cardError,
                          cvc: null,
                        });
                      }
                      if (event.empty) {
                        cardExpiryRef.current.focus();
                      }
                    }}
                    onReady={(element) => (cardCvcRef.current = element)}
                  />
                  {cardError.cvc && <div className="text-sm text-red-500">{cardError.cvc}</div>}
                </div>
              </div>
            </>
          )}

          <Button
            loading={loading}
            type="default"
            htmlType="submit"
            className="mt-8 font-[700]"
            disabled={!stripe || cardError.number || cardError.exp || cardError.cvc}
            size="large"
            onClick={handleAddPaymentMethod}
            icon={<LockOutlined />}
            shape='round'
          >
            {/* {user.pm_last_four ? 'Subscribe' : 'Continue Conversation'} */}
            Upgrade to PRO
          </Button>
          <p className="my-2 italic text-center text-gray-500"></p>
        </div>
      </div>
    </Modal>
  );
}

export default PayModal;
