import React from 'react';
import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import AbbySrc from '../../../assets/images/Abby_3x.png';
import LogoSrc from '../../../assets/images/logo.png';

import 'tailwindcss/tailwind.css';

const AbbyAd = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/login${location.search}`);
  };

  return (
    <div className={`flex flex-col items-center justify-around min-h-screen text-white bg-primary`}>
      <img src={AbbySrc} alt="abby_logo" width={64} />
      <div className="flex items-center justify-center mt-8 mb-8">
        <img src={LogoSrc} alt="logo" />
      </div>
      <div className="text-center">
        <p className="text-xl font-semibold mb-4">
          AI Therapy <br />
          In Your Pocket
        </p>
        <Button type="default" size="large" shape="round" className="!px-16" onClick={handleClick}>
          Get Started
        </Button>
        <div className="flex justify-between w-full px-4 mt-4 text-xs">
          <span>Available 24/7</span>
          <span>Free</span>
        </div>
      </div>
    </div>
  );
};

export default AbbyAd;
